@import "src/scss/_settings/colors";

.wrapper {
 display: flex;
}

.box {
 width: 50%;
 padding: 0 50px;
}

.text {
 position: relative;
 padding-left: 30px;

 &:not(:last-child) {
  margin-bottom: 30px;
 }

 &:before {
  content: attr(data-number);
  position: absolute;
  left: 0;
  top: 0;
  font-size: 14px;
  font-weight: bold;
 }
}

.linkText {
 font-size: 20px;
 margin-bottom: 20px;
}

.link {
 display: block;
 background: none;
 border: none;
 color: $primary;
 text-align: right;
 margin-top: 10px;
 font-weight: bold;
 cursor: pointer;
}