.grid {
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 30px;
  display: grid;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
