@import "src/scss/_settings/colors";

.box {
  position: relative;
  background-color: white;
  color: $primary;
  padding: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 200px;
  height: 200px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  z-index: 1;
  line-height: 1.5;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    content: '';
    background-color: white;
    border: solid 2px white;
    transition: 0.3s border-color, 0.3s transform;
    z-index: -1;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:hover {
    &::after {
      border-color: $primary;
      transform: scale(1.05);
    }
  }
}

.wrapper {
  display: flex;
  justify-content: center;
}