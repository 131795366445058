@import "src/scss/_settings/colors";

.headline {
  font-weight: bold;
  margin-bottom: 5px;

  &.h1 {
    font-size: 28px;
  }

  &.h2 {
    font-size: 22px;
  }

  &.red {
    color: $primary;
  }
}