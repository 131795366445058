@import "src/scss/_settings/colors";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(white, 0.8);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 101;

  &:hover {
    & * {
      stroke: rgba(white, 0.8);
    }
  }

  &:active {
    & * {
      stroke: rgb(171, 171, 171);
    }
  }
}

.wrapper {
  position: relative;
  width: 1400px;

  &::before {
    content: "";
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    border: white dashed 5px;
    border-radius: 40px;
    height: 550px;
    width: 850px;
  }
}

.video {
  background-color: gray;
  width: 100%;
}

.button {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
  background-color: white;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  outline: none;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: solid white 8px;
    width: 88px;
    height: 88px;
    border-radius: 100%;
  }

  &:hover {
    background-color: rgba(white, 0.8);
  }

  &:active {
    background-color: rgb(171, 171, 171);
  }
}