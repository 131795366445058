@import "src/scss/_settings/colors";

.label {
  position: relative;
  display: flex;
  padding-bottom: 100%;
  background-color: white;
  cursor: pointer;
  border: $border solid 1px;

  &::after {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    content: attr(data-title);
    text-align: center;
    color: $border;
  }
}

.wrapper {
  position: relative;
  display: block;
  width: 100%;
}

.preview {
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  z-index: 1;
  border-radius: 5px;
}