@import "src/scss/_settings/colors";

.button {
  background-color: $primary;
  border: none;
  border-radius: 100%;
  color: white;
  width: 50px;
  height: 50px;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;

  &:first-child {
    margin-right: 30px;
  }

  &:last-child {
    margin-left: 30px;
  }
}

.input {
  box-sizing: border-box;
  border-radius: 0;
  border: none;
  padding: 40px 60px;
  font-size: 50px;
  font-weight: bold;
  color: $primary;
  text-align: center;
  width: 400px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  margin-top: 20px;
  text-align: center;
}