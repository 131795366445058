@import "src/scss/_settings/colors";

.box {
 background-color: $grey;
 padding: 100px;
}

.error {
 background-color: rgba(red, 0.2);
 color: red;
 border-left: solid 2px red;
 border-top: none;
 border-bottom: none;
 border-right: none;
 padding: 30px 20px;
}