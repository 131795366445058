@import "src/scss/_settings/colors";

.sigBox {
  width: 50%;
  height: 100px;
  background-color: white;
  margin: 0 auto;
}

.preview {
  width: 250px;
  margin: 0 auto;
  cursor: pointer;
}