$animation-time: 0.2s;

.checkbox {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #D9DADC;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #fff;
  transition: box-shadow $animation-time;
  cursor: pointer;
  margin: 0 15px 0 0;

  &::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
    transition: left $animation-time;
  }

  &:focus {
    border-color: #4ed164;
  }

  &:checked {
    box-shadow: inset 20px 0 0 0 #4ed164;
    border-color: #4ed164;

    &::after {
      left: 20px;
      box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.label {
  cursor: pointer;
}