@import "src/scss/_settings/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 50px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 30px;
  }


  &:not(:last-child) {
    border-bottom: solid $border 1px;
  }
}

.headline {
  text-align: center;
  max-width: 400px;
  margin: 0 auto 50px;
}