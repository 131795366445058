@import "src/scss/_settings/colors";

.button {
  display: inline-block;
  background-color: $primary;
  color: white;
  border: $primary solid 2px;
  padding: 10px 40px;
  cursor: pointer;
  font-size: 18px;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    background-color: white;
    color: $primary;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.secondary {
  background-color: $dark-grey;
  border-color: $dark-grey;
  color: black;

  &:hover {
    background-color: $dark-dark-grey;
    border-color: $dark-dark-grey;
    color: white;
  }
}