@import "src/scss/_settings/colors";

.wrapper {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 10px;
  row-gap: 30px;
  display: grid;
}

.element {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  width: 100%;
  background-color: white;
  cursor: pointer;
  border: solid white 2px;
  transition: border-color 0.5s;
  color: $primary;
  font-weight: bold;

  &.active {
    border-color: $primary;
  }
}
