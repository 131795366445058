@import "src/scss/_settings/colors";

.grid {
 width: 80%;
 margin: 0 auto;
 display: flex;
}

.element {
 width: 50%;

 &:first-child {
  margin-right: 100px;
 }
}

.fileInputWrapper {
 grid-template-columns: 1fr 1fr;
 column-gap: 10px;
 row-gap: 30px;
 display: grid;
}

.formRow {
 display: grid;
 margin-bottom: 20px;
}