@import "src/scss/_settings/colors";

.wrapper {
  background-color: white;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 0;
  justify-content: flex-end;
}

.value {
  color: $primary;
  font-size: 22px;
  font-weight: bold;
}

.label {
  margin-bottom: 10px;
}

.element {
  &:not(:first-child) {
    padding-top: 20px;
  }

  &:not(:last-child) {
    border-bottom: solid $border 1px;
    padding-bottom: 20px;
  }
}