@import "src/scss/_settings/colors";

.input {
  background-color: white;
  border: solid $border 1px;
  border-radius: 0;
  height: 40px;
  line-height: 40px;
  padding: 2px 15px;
  color: black;
  font-size: 18px;
  outline: none;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border-color: black;
  }
}